<template>
  <div>
    <section class="container-fluid setcion_img">
      <div class="container">
        <div class="row pt-5">
          <div class="col text-start mt-5 pt-5">
            <h1 class="text-white text-uppercase">
              Terms and conditions
            </h1>
            <p class="text-white mt-4">Last updated on 5/9/2021 at 1:48 PM</p>
          </div>
        </div>
      </div>
    </section>
    <section class="container definitions mt-5">
      <div class="row">
        <div class="col-lg-8 p-0">
          <h1>1. Definitions</h1>
          <ul class="p-1">
            <li>
              <p class="text-white text-wrap">
                1. Cryptohopper : Cryptohopper B.V., a limited liability company
                incorporated under the laws of the Netherlands. The company is
                registered with the Dutch Chamber of Commerce under registration
                number 70270481. The company’s registered office is at Johan van
                Hasseltweg 18A, 1022 WV Amsterdam. Cryptohopper is also referred
                to as “we”.
              </p>
            </li>
            <li>
              <p class="text-white text-wrap">
                2. Account : the Account on the Platform created by User, which
                is limited for use by User via the Login Credentials.
              </p>
            </li>
            <li>
              <p class="text-white text-wrap">
                3. Signals : recommendations for buying or selling
                cryptocurrencies shared by third party “Signal Providers” (also
                named “Hoppers”) engaging with Cryptohopper. Users have the
                option to configure the software on the Platform as such that it
                will automatically buy currencies on the basis of information of
                these Signals.
              </p>
            </li>
            <li>
              <p class="text-white text-wrap">
                4. Platform : the platform Cryptohopper has developed to enable
                Users to connect with Signal Providers, enabling Users to trade
                in crypto currencies on the crypto market using a crypto trader
                bot and in accordance with Signals provided. The Platform is
                available through the Website and through the App.
              </p>
            </li>
            <li>
              <p class="text-white text-wrap">
                5. Subscription Term : the Initial Term of the Subscription,
                plus any subsequent renewal subscription term(s).
              </p>
            </li>
            <li>
              <p class="text-white text-wrap">
                6. User(s) : individual private person(s) or legal entity(ies)
                making use of the Services. Users are also referred to as “you”.
              </p>
            </li>
          </ul>
          <h1>2. Registration and personal account</h1>
          <ul class="p-1">
            <li>
              <p class="text-white text-wrap">
                1. If you want to use our Services, go to our Website and/or
                download our App.
              </p>
            </li>
            <li>
              <p class="text-white text-wrap">
                2. To make use of all the functions of the Platform,
                Cryptohopper requires you to register and create an Account. In
                order to create an Account, you must inter alia provide your
                e-mail address and choose a strong password, as instructed
                during the registration process. The e-mail address and chosen
                password, together, form the “Login Credentials”.
              </p>
            </li>
            <li>
              <p class="text-white text-wrap">
                3. Through the Account, only fiat money can be deposited. Once
                you have deposited money into the Account, it cannot be
                withdrawn. Any profits as a result of using the Hoppers can of
                course be withdrawn, in the form of fiat money. No
                cryptocurrencies can be deposited or withdrawn through the
                Account.
              </p>
            </li>
            <li>
              <p class="text-white text-wrap">
                4. Platform : the platform Cryptohopper has developed to enable
                Users to connect with Signal Providers, enabling Users to trade
                in crypto currencies on the crypto market using a crypto trader
                bot and in accordance with Signals provided. The Platform is
                available through the Website and through the App.
              </p>
            </li>
            <li>
              <p class="text-white text-wrap">
                5. You may not allow use of your Account by any third party. You
                are fully responsible for non-disclosure of your Login
                Credentials and the use of your Account. If, for whatsoever
                reason, your Account is blocked or deleted, you are no longer
                entitled to use the Platform, e.g. by using another Account
                and/or creating a new Account.
              </p>
            </li>
            <li>
              <p class="text-white text-wrap">
                6. You warrant that the information provided when creating an
                Account is correct and complete. You are responsible for the
                accuracy of the data in your Account.
              </p>
            </li>
            <li>
              <p class="text-white text-wrap">
                7. Cryptohopper is entitled, at all times, to no longer make
                available certain functionalities of the Account, Platform or
                other Services, to not grant an Account to any person and to
                demand additional requirements for granting an Account.
              </p>
            </li>
          </ul>
          <h1>3. Offers and prices</h1>
          <ul class="p-1">
            <li>
              <p class="text-white text-wrap">
                1. All offers and free trials regarding the Services are subject
                to these Terms.
              </p>
            </li>
            <li>
              <p class="text-white text-wrap">
                2. The prices on the Website, App and Platform do not include
                taxes and expenses, unless indicated otherwise.
              </p>
            </li>
            <li>
              <p class="text-white text-wrap">
                3. Cryptohopper has the right to amend its prices at any time.
                Such price change may take effect immediately.
              </p>
            </li>
            <li>
              <p class="text-white text-wrap">
                4. Cryptohopper cannot be held to an offer or quotation that can
                reasonably be understood to contain an obvious mistake or error.
              </p>
            </li>
          </ul>
          <h1>4. Subscription</h1>
          <ul class="p-1">
            <li>
              <p class="text-white text-wrap">
                1. You need a subscription to use all the functions of the
                Platform (the “Subscription”). Subscriptions are offered for a
                fixed amount per month and/or per year. You can apply for a
                Subscription on our Website.
              </p>
            </li>
            <li>
              <p class="text-white text-wrap">
                2. Cryptohopper offers several sorts of packages as a
                Subscription. Each package differs in the amount of positions,
                selected coins, frequency of scanning the stock market and the
                amount of support from Cryptohopper. You can find all packages
                on our Website.
              </p>
            </li>
            <li>
              <p class="text-white text-wrap">
                3. Without prejudice to the other provisions in these Terms, the
                Subscription shall commence and expire in accordance with the
                start date and end date indicated in the subscription package,
                unless earlier terminated by User in accordance with this
                article or by Cryptohopper if User violates these Terms and/or
                applicable laws and regulations (the “Initial Term”).
              </p>
            </li>
            <li>
              <p class="text-white text-wrap">
                4. Cryptohopper will give User access to the Platform
                corresponding to its Subscription immediately on the start date
                of such Subscription. User can use and set up one Hopper on the
                Platform on the basis of one subscription.
              </p>
            </li>
            <li>
              <p class="text-white text-wrap">
                5. After the Initial Term, the Subscription shall automatically
                renew for the same period as the Initial Term, unless
                Cryptohopper or User gives written notice via the Website or
                Platform to the other of its intention not to renew the
                Subscription. The Subscription will terminate one month after
                this notification.
              </p>
            </li>
            <li>
              <p class="text-white text-wrap">
                6. User has the right to withdraw the Subscription within 14
                days after having applied for the Subscription.
              </p>
            </li>
          </ul>
          <h1>5. Payment</h1>
          <ul class="p-1">
            <li>
              <p class="text-white text-wrap">
                1. The price of the subscription will be paid every month by
                User. Possible methods of payment are displayed on the Website
                and include for example PayPal, credit cards (e.g. Visa or
                MasterCard) and cryptocurrencies.
              </p>
            </li>
            <li>
              <p class="text-white text-wrap">
                2. Cryptohopper offers several sorts of packages as a
                Subscription. Each package differs in the amount of positions,
                selected coins, frequency of scanning the stock market and the
                amount of support from Cryptohopper. You can find all packages
                on our Website.
              </p>
            </li>
            <li>
              <p class="text-white text-wrap">
                3. The Account of the User will remain active for the period
                that User has already paid for.
              </p>
            </li>
          </ul>
        </div>
        <div class="col-lg-4">
          <div class="card card_doted p-4 bg-transparent">
            <h5>Get in touch with us.</h5>
            <div>
              <p>Address</p>
              <p>2715 Ash Dr. San Jose, South Dakota 83475</p>
            </div>
            <div>
              <p>Contact</p>
              <p>(406) 555-0120</p>
              <p>alma.lawson@example.com</p>
            </div>
          </div>
        </div>
      </div>
      <button class="btn_read mt-5">Read more</button>
    </section>
    <div class="mobile-cards container-fluid mt-5">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="title">
              <span>We Accept</span>
            </div>
          </div>
          <div
            class="col-md-9 d-flex flex-wrap align-items-center justify-content-between mt-md-0 mt-3"
          >
            <div class="item">
              <img src="@assets/img/Skill.png" alt="skrill" />
            </div>
            <div class="item">
              <img src="@assets/img/visa.png" alt="visa" />
            </div>
            <div class="item">
              <img src="@assets/img/mc.png" alt="master card" />
            </div>
            <div class="item">
              <img src="@assets/img/neteller.png" alt="neteller" />
            </div>
            <div class="item">
              <img src="@assets/img/wire.png" alt="wire" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'terms',
}
</script>

<style lang="scss">
.mobile-cards {
  padding: 43px 0 62px 0;
  background-color: #0d223c;
  display: flex;
  .title {
    text-align: center;
    span {
      font-size: 30px;
      font-weight: 800;
      color: #fff;
    }
  }

  .first-list-item {
    display: flex;
    justify-content: space-between;
    .item {
      img {
        width: 100%;
      }
    }
  }

  .second-list-item {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}
.setcion_img {
  height: 500px;
  background: url('~@assets/img/terms.jpg') no-repeat center;
  background-size: cover;
  h1 {
    font-weight: 800;
  }
}
.definitions {
  h1 {
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 37px;
    text-transform: capitalize;
    color: #f1f601;
  }
  ul {
    list-style: none;
    li {
      p {
        font-family: 'Gilroy';
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #eef0f3;
      }
    }
  }
}
.card_doted {
  border: 1px dashed #f1f601;
  box-sizing: border-box;
  border-radius: 20px;
  h5 {
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 32px;
    color: #f1f601;
  }
  p {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #ffffff;
  }
}
.btn_read {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  background: transparent;
  color: #ffffff;
  border: 2px solid #f1f601;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #f1f601;
  }
}
</style>
